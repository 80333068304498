var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "form-phone" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center" },
      [
        _c(_setup.CountryFlag, {
          staticClass: "ml-2 mt-3",
          attrs: { flag: _setup.fieldData.country?.countryCode },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "country-code-field" },
          [
            _c(_setup.FormSelect, {
              staticClass: "select-input",
              attrs: {
                label: "Country Code",
                state: _vm.state ?? null,
                options: _setup.countryCodeOptions,
              },
              model: {
                value: _setup.fieldData.country,
                callback: function ($$v) {
                  _vm.$set(_setup.fieldData, "country", $$v)
                },
                expression: "fieldData.country",
              },
            }),
            _vm._v(" "),
            _c(_setup.FormSelect, {
              staticClass: "select-input-mask",
              attrs: { label: "Country Code" },
              scopedSlots: _vm._u([
                {
                  key: "first",
                  fn: function () {
                    return [
                      _c(
                        "option",
                        { domProps: { value: _setup.fieldData.country } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_setup.fieldData.country?.countryCode) +
                              "  " +
                              _vm._s(_setup.fieldData.country?.dialCode) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _setup.fieldData.country,
                callback: function ($$v) {
                  _vm.$set(_setup.fieldData, "country", $$v)
                },
                expression: "fieldData.country",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(_setup.FormInput, {
          staticClass: "phone-number-field",
          attrs: {
            type: "tel",
            label: "Phone Number",
            state: _vm.state ?? null,
          },
          on: { input: _setup.asYouType },
          model: {
            value: _setup.fieldData.number,
            callback: function ($$v) {
              _vm.$set(_setup.fieldData, "number", $$v)
            },
            expression: "fieldData.number",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }