import { server } from '@api/request';
import * as models from '@models';
import { aggregateReports } from '@tools/simulator/aggregate-reports';
import * as typeGuards from '@tools/type-guards';

type PaginatedUsersResults = ZephyrWeb.Pagination.Results<models.User>;

/**
 * Data received when fetching the currently logged in user.
 */
export interface ActiveUser extends models.User {
  /**
   * Auth0 identities associated with the user.
   */
  identities: ActiveUser.Auth0Identity[];
  /**
   * Date (ISO string) when the user's password was last changed.
   */
  passwordChanged: string;
  /**
   * The number of days before the user's password must be changed, as dictated
   * by the organization's password rotation policy.
   */
  passwordRotationPolicy?: number;
  /**
   * If `true`, the user is a student under an organization that represents a
   * school (kindergarten - 12th grade).
   */
  isK12?: boolean;
}

export namespace ActiveUser {
  export interface Auth0Identity {
    user_id: string;
    connection: string;
    provider: string;
    isSocial: boolean;
    profileData: Record<string, unknown>;
  }
}

/**
 * Data received when an LAS Admin fetches another user.
 */
export interface AdminUser extends models.User {
  /**
   * The number of days before the user's password must be changed, as dictated
   * by the organization's password rotation policy.
   */
  passwordRotationPolicy?: number;
  /**
   * If `true`, the user is a student under an organization that represents a
   * school (kindergarten - 12th grade).
   */
  isK12?: boolean;
  /**
   * The available options for a user to select for updating their password
   * rotation policy
   * "NONE", "90_DAYS", "180_DAYS", or "365_DAYS"
   */
  passwordRotationOptions: string[];
}

/**
 * ...
 */
export type AggregateReportResults = Record<
  models.User['id'],
  aggregateReports.Results
>;

/**
 * List all users.
 *
 * @param options Request options payload.
 * @returns A list of users.
 *
 * @see {@link ZephyrApi.Admin.Users.listUsers `AdminListUsers`} API route in `zephyr-serverless`
 */
export async function list() {
  return await server.list('v2/admin/users', processUserData);
}

/**
 * {@link listByOrganization listByOrganization} API request payload options.
 */
export interface ListByOrganizationOptions {
  organizationId: models.Organization['id'];
}

/**
 * List all users for a specified organization.
 *
 * @param options Request options payload.
 * @returns A list of users.
 *
 * @see {@link ZephyrApi.Organizations.listUsers `ListOrganizationUsers`} API route in `zephyr-serverless`
 */
export async function listByOrganization(options: ListByOrganizationOptions) {
  return await server.list(
    `v2/organizations/${options.organizationId}/users`,
    processUserData,
  );
}

/**
 * List all students for a specified organization.
 *
 * @param options Request options payload.
 * @returns A list of users.
 *
 * @see {@link ZephyrApi.Organizations.listStudents `ListOrganizationStudents`} API route in `zephyr-serverless`
 */
export async function listStudentsByOrganization(
  options: ListByOrganizationOptions,
) {
  return await server.list(
    `v2/organizations/${options.organizationId}/students`,
    processUserData,
  );
}

/**
 * List all instructors for a specified organization.
 *
 * @param options Request options payload.
 * @returns A list of users.
 *
 * @see {@link ZephyrApi.Organizations.listInstructors `ListOrganizationInstructors`} API route in `zephyr-serverless`
 */
export async function listInstructorsByOrganization(
  options: ListByOrganizationOptions,
) {
  return await server.list(
    `v2/organizations/${options.organizationId}/instructors`,
    processUserData,
  );
}

/**
 * List all admins for a specified organization.
 *
 * @param options Request options payload.
 * @returns A list of users.
 *
 * @see {@link ZephyrApi.Organizations.listAdmins `ListOrganizationAdmins`} API route in `zephyr-serverless`
 */
export async function listAdminsByOrganization(
  options: ListByOrganizationOptions,
) {
  return await server.list(
    `v2/organizations/${options.organizationId}/admins`,
    processUserData,
  );
}

/**
 * {@link listByCourse listByCourse} API request payload options.
 */
export interface ListByCourseOptions {
  organizationId: models.Organization['id'];
  courseId: models.Course['id'];
}

/**
 * List all users belonging to a specified course.
 *
 * @param options Request options payload.
 * @returns A list of users.
 *
 * @see {@link ZephyrApi.Organizations.listCourseUsers `ListCourseUsers`} API route in `zephyr-serverless`
 */
export async function listByCourse(options: ListByCourseOptions) {
  return await server.list(
    `v2/organizations/${options.organizationId}/courses/${options.courseId}/users`,
    processUserData,
  );
}

/**
 * {@link search Search users} API request namespace.
 */
export namespace UserSearch {
  /**
   * {@link search Search users} API request options.
   */
  export interface Options {
    includeRoles: boolean;
    includeLicenses: boolean;
    includeCertifications?: boolean;
    organizationId?: models.Organization['id'];
    users?: models.User['id'][];
    equals?: Record<string, boolean>;
    contains?: string | Record<string, boolean>;
    admin?: boolean;
    limit?: number;
    startKey?: ZephyrWeb.Pagination.PlacementKey;
  }

  /**
   * {@link search Search users} API request response.
   */
  export type Results = PaginatedUsersResults;
}

/**
 * Search for users by a specified criteria (paginated).
 *
 * @param options Request options payload.
 * @returns Search {@link UserSearch.Results results} data object.
 *
 * @see {@link ZephyrApi.Admin.Users.search `AdminSearchUsers`} API route in `zephyr-serverless`
 */
export async function search(options: UserSearch.Options) {
  return await server.post(
    'v2/admin/users/search',
    options,
    processSearchResults,
  );
}

/**
 * {@link searchAuditLogs Search user audit logs} API request namespace.
 */
export namespace UserAuditLogsSearch {
  /**
   * {@link searchAuditLogs Search user audit logs} API request options.
   */
  export interface Options {
    userId: models.User['id'];
    // startDate?: number; // unix epoch
    // endDate?: number; // unix epoch
    nextToken?: string | null;
  }

  /**
   * {@link searchAuditLogs Search user audit logs} API request response.
   */
  export interface Results {
    events?: models.OutputLogEvent[];
    nextForwardToken?: string | null;
    nextBackwardToken?: string | null;
  }
}

/**
 * Search for user logs by a specified criteria (paginated).
 *
 * @param options Request options payload.
 * @returns Search {@link UserAuditLogsSearch.Results results} data object.
 *
 * @see {@link ZephyrApi.Admin.Users.searchLogs `AdminSearchUserLogs`} API route in `zephyr-serverless`
 */
export async function searchAuditLogs(options: UserAuditLogsSearch.Options) {
  return await server.post(
    `v2/admin/users/logs/search`,
    options,
    processAuditLogSearchResults,
  );
}

/**
 * {@link searchByOrganization Search users by organization} API request namespace.
 */
export namespace UserSearchByOrganization {
  /**
   * {@link searchByOrganization Search users by organization} API request options.
   */
  export interface Options {
    organizationId: models.Organization['id'];
    includeRoles: boolean;
    includeLicenses: boolean;
    includeCertifications?: boolean;
    users?: string[];
    equals?: Record<string, boolean>;
    contains?: string | Record<string, boolean>;
    admin?: boolean;
    limit?: number;
    startKey?: ZephyrWeb.Pagination.PlacementKey;
  }

  /**
   * {@link searchByOrganization Search users by organization} API request response.
   */
  export type Results = PaginatedUsersResults;
}

/**
 * Search for users under a given organization by a specified criteria
 * (paginated).
 *
 * @param options Request options payload.
 * @returns Search {@link UserSearchByOrganization.Results results} data object.
 *
 * @see {@link ZephyrApi.Admin.Users.searchLogs `AdminSearchUserLogs`} API route in `zephyr-serverless`
 */
export async function searchByOrganization(
  options: UserSearchByOrganization.Options,
) {
  return await server.post(
    `v2/organizations/${options.organizationId}/users/search`,
    options,
    processSearchResults,
  );
}

export namespace UserSearchByCourse {
  /**
   * {@link searchByCourse Search users by course} API request options.
   */
  export interface Options {
    organizationId: models.Organization['id'];
    courseId: models.Course['id'];
    includeRoles: boolean;
    includeLicenses: boolean;
    includeCertifications?: boolean;
    users?: string[];
    equals?: Record<string, boolean>;
    contains?: string | Record<string, boolean>;
    admin?: boolean;
    limit?: number;
    startKey?: ZephyrWeb.Pagination.PlacementKey;
  }

  /**
   * {@link searchByCourse Search users by course} API request response.
   */
  export type Results = PaginatedUsersResults;
}

/**
 * Search for users under a given course by a specified criteria
 * (paginated).
 *
 * @param options Request options payload.
 * @returns Search {@link UserSearchByCourse.Results results} data object.
 *
 */
export async function searchByCourse(options: UserSearchByCourse.Options) {
  return await server.post(
    `v2/organizations/${options.organizationId}/courses/${options.courseId}/users/search`,
    options,
    processSearchResults,
  );
}

export async function getMyPasswordRotationOptions() {
  return await server.get<models.Organization.PasswordRotationPolicy[]>(
    'v2/users/password-rotation-options',
  );
}

export async function getMyAuthenticationMethods() {
  return await server.get<models.AuthenticationMethod[]>(
    'v2/users/authentication-methods',
  );
}

/**
 * {@link startMfaEnrollment Start MFA enrollment} API request namespace.
 */
export interface StartMFAEnrollmentOptions {
  type: 'totp';
}

/**
 * {@link startMfaEnrollment Start MFA enrollment} API request response.
 */
export interface StartMfaResponse {
  secret: string;
}

/**
 * Start enrollment process for using Multi-Factor Authentication (MFA) with
 * current user account.
 *
 * @param options Request options payload.
 * @returns Response data object, including an authentication secret value.
 *
 * @see {@link ZephyrApi.Users.startEnrollmentMFA `StartMFA`} API route in `zephyr-serverless`
 */
export async function startMfaEnrollment(options: StartMFAEnrollmentOptions) {
  return await server.post<StartMfaResponse>(`v2/users/start-mfa`, options);
}

/**
 * {@link enrollInMfa Enroll in MFA} API request namespace.
 */
export interface EnrollInMfaOptions {
  secret: string;
  token: string;
}

/**
 * Complete enrollment in use of Multi-Factor Authentication (MFA) for the
 * current user account.
 *
 * @param options Request options payload.
 *
 * @see {@link ZephyrApi.Users.enrollMFA `EnrollMFA`} API route in `zephyr-serverless`
 */
export async function enrollInMfa(options: EnrollInMfaOptions) {
  await server.post('v2/users/enroll-mfa', options);
}

export interface DeleteAuthenticationMethodByIdOptions {
  authenticationMethodId: string;
}

/**
 * ...
 *
 * @param options Request options payload.
 * @returns an empty response.
 */

export async function deleteAuthenticationMethodById(
  options: DeleteAuthenticationMethodByIdOptions,
) {
  return await server.delete(
    `v2/users/authentication-methods/${options.authenticationMethodId}`,
  );
}

/**
 * {@link get get} API request payload options.
 */
export interface GetOptions {
  userId: models.User['id'];
  organizationId?: models.Organization['id'];
}

/**
 * ...
 *
 * @param options Request options payload.
 * @returns A user object.
 */
export async function get(options: GetOptions) {
  const url = options.organizationId
    ? `v2/organizations/${options.organizationId}/users/${options.userId}`
    : `v2/admin/users/${options.userId}`;

  return (await server.get(
    url,
    options.organizationId ? processUserData : processAdminUserData,
  )) as models.User | AdminUser;
}

/**
 * {@link getByOrganization getByOrganization} API request payload options.
 */
export interface GetByOrganizationOptions {
  userId: models.User['id'];
  organizationId: models.Organization['id'];
}

/**
 * ...
 *
 * @param options Request options payload.
 * @returns The active user.
 */
export async function getByOrganization(options: GetByOrganizationOptions) {
  return await server.get(
    `v2/organizations/${options.organizationId}/users/${options.userId}`,
    processUserData,
  );
}

/**
 * Get active user.
 *
 * @returns The active user.
 *
 * @see {@link ZephyrApi.Users.getMe `GetMe`} API route in `zephyr-serverless`
 */
export async function getMe() {
  return await server.get('v2/users/me', processActiveUserData);
}

/**
 * {@link getPublicProfile getPublicProfile} API request payload options.
 */
export interface GetPublicProfileOptions {
  userId: models.User['id'];
}

/**
 * Get a specified user's public profile info.
 *
 * @returns The active user.
 *
 * @see {@link ZephyrApi.Users.getUser `GetUser`} API route in `zephyr-serverless`
 */
export async function getPublicProfile(options: GetPublicProfileOptions) {
  return await server.get<models.User.PublicProfileInfo>(
    `v2/users/${options.userId}`,
  );
}

/**
 * {@link update update} API request payload options.
 *
 * @todo Update to include all proper updatable user properties based on API.
 */
export interface UpdateOptions {
  userId: models.User['id'];
  firstName?: models.User['firstName'];
  lastName?: models.User['lastName'];
  email?: models.User['email'];
  birthdate?: models.User['birthdate'];
  phone?: models.User['phone'];
  privacy?: models.User.PrivacyInfo | null;
  address?: models.User.AddressInfo | null;
  emailVerified?: models.User['emailVerified'] | null;
  active?: models.User['active'] | null;
  password?: string;
}

/**
 * Update specified user.
 *
 * @param options Request options payload.
 * @returns The updated user.
 *
 * @see {@link ZephyrApi.Admin.Users.updateUser `UpdateUser`} API route in `zephyr-serverless`
 */
export async function update(options: UpdateOptions) {
  const { userId, ...data } = options;

  return await server.post(
    `v2/admin/users/${options.userId}`,
    data,
    processUserData,
  );
}

/**
 * {@link updateMe updateMe} API request payload options.
 */
export interface UpdateMeOptions {
  firstName?: models.User['firstName'];
  lastName?: models.User['lastName'];
  email?: models.User['email'];
  birthdate?: models.User['birthdate'];
  phone?: models.User['phone'];
  privacy?: models.User.PrivacyInfo | null;
  address?: Partial<models.User.AddressInfo> | null;
  password?: string;
}

/**
 * Update the current active user.
 *
 * @param options Request options payload.
 * @returns The updated user.
 *
 * @see {@link ZephyrApi.Users.update `UpdateUser`} API route in `zephyr-serverless`
 */
export async function updateMe(options: UpdateMeOptions) {
  return await server.post('v2/users/me', options);
}

/**
 * {@link changeMyPassword changeMyPassword} API request payload options.
 */
export interface ChangeMyPasswordOptions {
  newPassword: string;
}

/**
 * Update the current user's password.
 *
 * @param options Request options payload.
 *
 * @see {@link ZephyrApi.Users.changePassword `ChangePassword`} API route in `zephyr-serverless`
 */
export async function changeMyPassword(options: ChangeMyPasswordOptions) {
  await server.post('v2/users/change-password', options);
}

/**
 * {@link linkAccount Link account} API request payload options.
 */
export interface LinkAccountOptions {
  userId: string;
  accountIdToken: string;
}

/**
 * Link a user's Zephyr account with a (supported) external account.
 *
 * @param options Request options payload.
 *
 * @see {@link ZephyrApi.Users.linkUsers `LinkUsers`} API route in `zephyr-serverless`
 */
export async function linkAccount(options: LinkAccountOptions) {
  const { userId, accountIdToken: link_with } = options;

  await server.post(`v2/users/${userId}/identities`, { link_with });
}

/**
 * {@link unlinkAccount Unlink account} API request payload options.
 */
export interface UnlinkAccountOptions {
  userId: string;
  targetUserId: string;
  provider: string;
}

/**
 * Unlink a user's Zephyr account from a previously linked external account.
 *
 * @param options Request options payload.
 *
 * @see {@link ZephyrApi.Users.unlinkUsers `UnlinkUsers`} API route in `zephyr-serverless`
 */
export async function unlinkAccount(options: UnlinkAccountOptions) {
  const { userId, provider, targetUserId } = options;

  await server.delete(
    `v2/users/${userId}/identities/${provider}/${targetUserId}`,
  );
}

/**
 * {@link adminAddLicense adminAddLicense} API request payload options.
 */
export interface AdminAddLicenseOptions {
  userId: models.User['id'];
  productId: models.Product['id'];
  expiration?: models.License['expiration'];
}

/**
 * LAS Admin add user licenses.
 *
 * @param options Request options payload.
 * @returns ...
 */
export async function adminAddLicense(options: AdminAddLicenseOptions) {
  const { userId, ...data } = options;

  return await server.post<models.License>(
    `v2/admin/users/${userId}/add-license`,
    data,
  );
}

/**
 * {@link adminRemoveLicense adminRemoveLicense} API request payload options.
 */
export interface AdminRemoveLicenseOptions {
  userId: models.User['id'];
  licenseKey: models.License['id'];
}

/**
 * LAS Admin remove user licenses.
 *
 * @param options Request options payload.
 * @returns ...
 */
export async function adminRemoveLicense(options: AdminRemoveLicenseOptions) {
  const { userId, ...data } = options;

  await server.post(`v2/admin/users/${userId}/remove-license`, data);
}

/**
 * {@link addRole addRole} API request payload options.
 */
export interface AddRoleOptions {
  roleId: models.Role['roleId'];
  userId: models.User['id'];
  organizationId?: models.Organization['id'];
  courseId?: models.Course['id'];
  certifications?: models.Product['id'][];
  deductSeat?: boolean;
}

/**
 * LAS Admin add user role.
 *
 * @param options Request options payload.
 * @returns ...
 */
export async function addRole(options: AddRoleOptions) {
  const { userId, ...data } = options;

  return await server.post<models.Role>(
    `v2/admin/users/${userId}/add-role`,
    data,
  );
}

/**
 * {@link updateRole updateRole} API request payload options.
 */
export interface UpdateRoleOptions {
  userId: models.User['id'];
  assignedRoleId: models.Role['id'];
  organizationId?: models.Organization['id'];
  courseId?: models.Course['id'];
  roleId?: models.Role['roleId'];
  subStart?: string | null;
  subEnd?: string | null;
  certifications?: models.Product['id'][];
}

/**
 * LAS Admin update user role.
 *
 * @param options Request options payload.
 * @returns ...
 */
export async function updateRole(options: UpdateRoleOptions) {
  const { userId, ...data } = options;

  return await server.post(`v2/admin/users/${userId}/update-role`, data);
}

/**
 * {@link removeRole removeRole} API request payload options.
 */
export interface RemoveRoleOptions {
  assignedRoleId: models.AssignedRole['id'];
  userId: models.User['id'];
}

/**
 * LAS Admin remove user role.
 *
 * @param options Request options payload.
 * @returns ...
 */
export async function removeRole(options: RemoveRoleOptions) {
  return await server.post(
    `v2/admin/users/${options.userId}/remove-role`,
    options,
  );
}

/**
 * {@link linkDroneLogbook linkDroneLogbook} API request payload options.
 */
export interface LinkDroneLogbookOptions {
  email: string;
  password: string;
  serverUrl?: string;
}

/**
 * Link to dronelogbook account
 *
 * @param options Request options payload.
 *
 * @see {@link ZephyrApi.Users.DroneLogbook.link `LinkDroneLogbook`} API route in `zephyr-serverless`
 */
export async function linkDroneLogbook(options: LinkDroneLogbookOptions) {
  await server.post('v2/users/dronelogbook/link', options);
}

/**
 * Unlink dronelogbook account.
 *
 * @see {@link ZephyrApi.Users.DroneLogbook.unlink `UnlinkDroneLogbook`} API route in `zephyr-serverless`
 */
export async function unlinkDroneLogbook() {
  await server.delete('v2/users/dronelogbook/unlink');
}

/**
 * {@link resetPassword resetPassword} API request payload options.
 */
export interface ResetPasswordOptions {
  resetKey: string;
  password: string;
}

/**
 * Reset password with reset ID.
 *
 * @param options Request options payload.
 * @returns ...
 */
export async function resetPassword(options: ResetPasswordOptions) {
  await server.post(`v2/reset-password`, options);
}

/**
 * ...
 */
export interface DeleteUserOptions {
  userId: models.User['id'];
}

/**
 * Delete user from Zephyr
 * THIS IS A VERY DANGEROUS ROUTE
 *
 * * @param options Request options payload.
 * @returns ...
 */
export async function deleteUser(options: DeleteUserOptions) {
  await server.post(`v2/admin/users/delete`, options);
}

//#region Helper Functions

/**
 * Determine if a value is a valid {@link models.User}.
 *
 * @param value The value to check.
 * @returns `true` if the value is a valid {@link models.User},
 * otherwise `false`.
 */
function isValidUserData(value: unknown): value is models.User {
  if (!typeGuards.isObject(value)) return false;

  // return (
  //   (typeGuards.isString(value['avatar']) ||
  //     typeGuards.isNull(value['avatar'])) &&
  //   typeGuards.isString(value['lastName']) &&
  //   typeGuards.isBoolean(value['isDroneLogbookLinked']) &&
  //   typeGuards.isString(value['email']) &&
  //   typeGuards.isString(value['name']) &&
  //   typeGuards.isString(value['firstName']) &&
  //   typeGuards.isBoolean(value['syncingDroneLogbook']) &&
  //   typeGuards.isString(value['sk']) &&
  //   typeGuards.isString(value['id']) &&
  //   (typeGuards.isString(value['phone']) ||
  //     typeGuards.isNull(value['phone'])) &&
  //   typeGuards.isString(value['dataModel']) &&
  //   typeGuards.isString(value['passwordChanged']) &&
  //   typeGuards.isString(value['lastSimLogin']) &&
  //   typeGuards.isString(value['createdAt']) &&
  //   typeGuards.isObject(value['address']) &&
  //   typeGuards.isNumber(value['realLifeTime']) &&
  //   typeGuards.isString(value['larn']) &&
  //   typeGuards.isArray(value['roles']) &&
  //   typeGuards.isNumber(value['simulatorTime']) &&
  //   typeGuards.isString(value['birthdate']) &&
  //   typeGuards.isString(value['updatedAt']) &&
  //   typeGuards.isArray(value['licenses']) &&
  //   typeGuards.isString(value['lastWebLogin']) &&
  //   typeGuards.isString(value['username']) &&
  //   typeGuards.isString(value['pk'])
  // );

  return true;
}

/**
 * Process data received from a request expected to be a {@link models.User}.
 *
 * @param data Data received from the request.
 * @returns The processed data value.
 */
function processUserData(data: unknown) {
  if (!isValidUserData(data)) {
    throw new Error('Invalid user data item.');
  }

  return data;
}

/**
 * Determine if a value is a valid {@link ActiveUser}.
 *
 * @param value The value to check.
 * @returns `true` if the value is a valid {@link ActiveUser}, otherwise
 * `false`.
 */
function isValidActiveUserData(value: unknown): value is ActiveUser {
  if (!isValidUserData(value)) return false;

  // return (
  //   (typeGuards.isString(value['avatar']) ||
  //     typeGuards.isNull(value['avatar'])) &&
  //   typeGuards.isString(value['lastName']) &&
  //   typeGuards.isBoolean(value['isDroneLogbookLinked']) &&
  //   typeGuards.isString(value['email']) &&
  //   typeGuards.isString(value['name']) &&
  //   typeGuards.isString(value['firstName']) &&
  //   typeGuards.isBoolean(value['syncingDroneLogbook']) &&
  //   typeGuards.isString(value['sk']) &&
  //   typeGuards.isString(value['id']) &&
  //   (typeGuards.isString(value['phone']) ||
  //     typeGuards.isNull(value['phone'])) &&
  //   typeGuards.isString(value['dataModel']) &&
  //   typeGuards.isString(value['passwordChanged']) &&
  //   typeGuards.isString(value['lastSimLogin']) &&
  //   typeGuards.isString(value['createdAt']) &&
  //   typeGuards.isObject(value['address']) &&
  //   typeGuards.isNumber(value['realLifeTime']) &&
  //   typeGuards.isString(value['larn']) &&
  //   typeGuards.isArray(value['roles']) &&
  //   typeGuards.isNumber(value['simulatorTime']) &&
  //   typeGuards.isString(value['birthdate']) &&
  //   typeGuards.isString(value['updatedAt']) &&
  //   typeGuards.isArray(value['licenses']) &&
  //   typeGuards.isString(value['lastWebLogin']) &&
  //   typeGuards.isString(value['username']) &&
  //   typeGuards.isString(value['pk'])
  // );

  return true;
}

/**
 * Process data received from a request expected to be a {@link ActiveUser}.
 *
 * @param data Data received from the request.
 * @returns The processed data value.
 */
function processActiveUserData(data: unknown) {
  if (!isValidActiveUserData(data)) {
    throw new Error('Invalid active user data item.');
  }

  return data;
}

/**
 * Determine if a value is a valid {@link AdminUser}.
 *
 * @param value The value to check.
 * @returns `true` if the value is a valid {@link ActiveUser}, otherwise
 * `false`.
 */
function isValidAdminUserData(value: unknown): value is AdminUser {
  if (!isValidUserData(value)) return false;

  return true;
}

/**
 * Process data received from a request expected to be a {@link AdminUser}.
 *
 * @param data Data received from the request.
 * @returns the processed data value
 */
function processAdminUserData(data: unknown) {
  if (!isValidAdminUserData(data)) {
    throw new Error('Invalid admin user data item.');
  }

  return data;
}

/**
 * Determine if a value is a valid {@link UserSearch.Results}.
 *
 * @param value The value to check.
 * @returns `true` if the value is a valid {@link UserSearch.Results},
 * otherwise `false`.
 */
function isValidSearchResults(value: unknown): value is UserSearch.Results {
  return (
    typeGuards.isObject(value) &&
    typeGuards.isArray(value.items) &&
    (typeGuards.isObject(value.lastEvaluatedKey) ||
      typeGuards.isNull(value.lastEvaluatedKey))
  );
}

/**
 * Process data received from a request expected to be a
 * {@link UserSearch.Results}.
 *
 * @param data Data received from the request.
 * @returns The processed data value.
 */
function processSearchResults(data: unknown) {
  if (!isValidSearchResults(data)) {
    throw new Error('Invalid user search result data.');
  }

  return data;
}

/**
 * Determine if a value is a valid {@link UserAuditLogsSearch.Results}.
 *
 * @param value The value to check.
 * @returns `true` if the value is a valid {@link UserAuditLogsSearch.Results},
 * otherwise `false`.
 */
function isValidAuditLogSearchResults(
  value: unknown,
): value is UserAuditLogsSearch.Results {
  return typeGuards.isObject(value) && typeGuards.isArray(value.events);
}

/**
 * Process data received from a request expected to be a
 * {@link UserAuditLogsSearch.Results}.
 *
 * @param data Data received from the request.
 * @returns The processed data value.
 */
function processAuditLogSearchResults(data: unknown) {
  if (!isValidAuditLogSearchResults(data)) {
    throw new Error('Invalid audit log search result data.');
  }

  return data;
}

//#endregion Helper Functions
