var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: _setup.classList },
    [
      _c(_setup.Toolbar, {
        on: {
          maximize: function ($event) {
            _setup.fullscreen = true
          },
          minimize: function ($event) {
            _setup.fullscreen = false
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c(
            "Transition",
            { attrs: { appear: "" } },
            [
              _setup.tableOverlay
                ? _c(
                    _setup.TableOverlay,
                    _vm._b({}, "TableOverlay", _setup.tableOverlay, false)
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "table-wrapper" },
            [
              _c("b-table", {
                class: ["mb-0", { "is-busy": _setup.loadingPage }],
                attrs: {
                  busy: _setup.loadingPage,
                  items: _setup.items,
                  fields: _setup.fields,
                  "per-page": 0,
                  "sort-compare": _setup.sortCompare,
                  striped: "",
                  "sticky-header": "auto",
                },
                on: {
                  "update:busy": function ($event) {
                    _setup.loadingPage = $event
                  },
                  "row-contextmenu": _setup.openFlyout,
                },
                nativeOn: {
                  scroll: function ($event) {
                    return _setup.onScroll.apply(null, arguments)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "head(selector)",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "table-cell-checkbox" }, [
                            _c(
                              "div",
                              [
                                _c("InputCheckbox", {
                                  attrs: { size: "sm" },
                                  model: {
                                    value: _setup.allRowsSelected,
                                    callback: function ($$v) {
                                      _setup.allRowsSelected = $$v
                                    },
                                    expression: "allRowsSelected",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "head(operations)",
                      fn: function (column) {
                        return [
                          _c("div", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(column.label)),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(selector)",
                      fn: function ({ index }) {
                        return [
                          _c("div", { staticClass: "table-cell-checkbox" }, [
                            _c(
                              "div",
                              [
                                _setup.page[index]
                                  ? _c("InputCheckbox", {
                                      attrs: { size: "sm" },
                                      model: {
                                        value: _setup.page[index].selected,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _setup.page[index],
                                            "selected",
                                            $$v
                                          )
                                        },
                                        expression: "page[index].selected",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                    },
                    _vm._l(_setup.customCells, function (cell) {
                      return {
                        key: `cell(${cell.key})`,
                        fn: function (data) {
                          return [
                            _c(
                              cell.component,
                              _vm._b(
                                { key: cell.key, tag: "component" },
                                "component",
                                data,
                                false
                              )
                            ),
                          ]
                        },
                      }
                    }),
                    {
                      key: "cell(operations)",
                      fn: function ({ item }) {
                        return [
                          _setup.operations && item
                            ? _c(
                                _setup.CellOperations,
                                _vm._b(
                                  {},
                                  "CellOperations",
                                  { item, operations: _setup.operations },
                                  false
                                )
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _setup.displayedItems,
                  callback: function ($$v) {
                    _setup.displayedItems = $$v
                  },
                  expression: "displayedItems",
                },
              }),
              _vm._v(" "),
              _c("Transition", [
                _setup.loadingPage
                  ? _c("div", { staticClass: "table-progress-bar" }, [
                      _c("span", {
                        style: { right: `${100 - _setup.loadProgress}%` },
                        on: { transitionend: _setup.onAnimationEnd },
                      }),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-footer p-4" },
        [
          _c(
            "b-row",
            { staticClass: "justify-content-between align-items-center" },
            [
              _c("b-col", { staticClass: "col-auto" }, [
                _vm._v(" " + _vm._s(_setup.items.length) + " result(s) "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }