import { server } from '@api/request';
import { isObject } from '@tools/type-guards';
import { Stripe } from 'stripe';

export interface RetrieveCustomerOptions {
  customerId: string;
}

export async function retrieveCustomer(options: RetrieveCustomerOptions) {
  return await server.get(
    `v2/admin/stripe/customers/${options.customerId}`,
    processRetrieveCustomerResponseData,
  );
}

function isValidRetrieveCustomerResponseData(
  value: unknown,
): value is Stripe.Response<Stripe.Customer> {
  if (!isObject(value)) return false;

  return true;
}

function processRetrieveCustomerResponseData(data: unknown) {
  if (!isValidRetrieveCustomerResponseData(data)) {
    throw new Error('Invalid retrieve customer response data item');
  }

  return { ...data } as Stripe.Response<Stripe.Customer>;
}
