import { server } from '@api/request';
import { dataUtils } from '@api/data-utils';
import * as models from '@models';
import { parse } from '@tools/safe-json-parse';
import { isArray, isString, isObject, isNull } from '@tools/type-guards';

type PaginatedOrdersResults = ZephyrWeb.Pagination.Results<models.Order>;

/**
 * {@link search Search orders} API request namespace.
 */
export namespace OrderSearch {
  /**
   * {@link search Search orders} API request options.
   */
  export interface Options {
    orderId?: string;
    email?: string;
    order?: string;
    hideFreeOrders?: boolean;
    onlyReferralOrders?: boolean;
    startDate?: string;
    endDate?: string;
    limit?: number;
    startKey?: ZephyrWeb.Pagination.PlacementKey;
  }

  /**
   * {@link search Search orders} API request response.
   */
  export type Results = PaginatedOrdersResults;
}

/**
 * Search for order details by a specified criteria (paginated).
 *
 * @param options Request options bag.
 * @returns `OrderSearch.Results` data object.
 *
 * @see {@link ZephyrApi.Admin.Orders.listOrders `AdminSearchOrders`} API route in `zephyr-serverless`
 */
export async function search(options: OrderSearch.Options) {
  return await server.post('v2/admin/orders', options, processSearchResults);
}

/**
 * List all of the current user's orders
 *
 * @returns A list of orders.
 *
 * @see {@link ZephyrApi.Users.listOrders `GetMyOrders`} API route in `zephyr-serverless`
 */
export async function listMine() {
  return await server.list('v2/users/orders', processOrderData);
}

/**
 * {@link listByUser List orders by user} API request options.
 */
export interface ListByUserOptions {
  userId: models.User['id'];
}

/**
 * List all orders belonging to a specified user.
 *
 * @param options Request options bag.
 * @returns A list of scenes.
 *
 * @see {@link ZephyrApi.Admin.Orders.listOrders `AdminListUserOrders`} API route in `zephyr-serverless`
 */
export async function listByUser(options: ListByUserOptions) {
  return await server.list(
    `v2/admin/users/${options.userId}/orders`,
    processOrderData,
  );
}

/**
 * {@link listByOrganization List orders by organization} API request options.
 */
export interface ListByOrganizationOptions {
  organizationId: models.Organization['id'];
}

/**
 * List all orders belonging to a specified organization.
 *
 * @param options Request options bag.
 * @returns A list of orders.
 *
 * @see {@link ZephyrApi.Organizations.listOrders `ListOrganizationOrders`} API route in `zephyr-serverless`
 */
export async function listByOrganization(options: ListByOrganizationOptions) {
  return await server.list(
    `v2/organizations/${options.organizationId}/orders`,
    processOrderData,
  );
}

/**
 * {@link get Get order} API request options.
 */
export interface GetOptions {
  orderId: models.Order['id'];
  recaptcha?: string;
  admin?: boolean;
}

/**
 * Get a specified order.
 *
 * @param options Request options bag.
 * @returns The specified order.
 *
 * @see {@link ZephyrApi.Orders.getOrder `GetOrder`} API route in `zephyr-serverless`
 * @see {@link ZephyrApi.Admin.Orders.adminGetOrder `AdminGetOrder`} API route in `zephyr-serverless`
 */
export async function get(options: GetOptions) {
  const { orderId, admin, recaptcha } = options;

  if (admin) {
    return await server.get(`v2/admin/orders/${orderId}`, processOrderData);
  }

  return await server.post(
    `v2/orders/${orderId}`,
    { recaptcha },
    processOrderData,
  );
}

/**
 * {@link getPublic Get public order} API request options.
 */
export interface GetPublicOptions {
  orderId: string;
  email: string;
  reCaptcha: string;
}

/**
 * Get (public) order details.
 *
 * @param options Request options bag.
 * @returns The specified (public) order details.
 *
 * @see {@link ZephyrApi.Orders.getOrder `PublicGetOrderDetails`} API route in `zephyr-serverless`
 */
export async function getPublic(options: GetPublicOptions) {
  const payload = {
    email: options.email,
    reCaptcha: options.reCaptcha,
  };

  return await server.post(
    `v2/orders/${options.orderId}`,
    payload,
    processOrderData,
  );
}

/**
 * {@link assign Assign order} API request options.
 */
export interface AssignOrderOptions {
  orderId: models.Order['id'];
  userId: models.User['id'];
}

/**
 * Assign order to user.
 *
 * @param options Request options bag.
 * @returns The assigned order.
 *
 * @see {@link ZephyrApi.Admin.Orders.assignOrder `AdminAssignOrder`} API route in `zephyr-serverless`
 */
export async function assign(options: AssignOrderOptions) {
  return await server.post(`v2/admin/orders/assign`, options, processOrderData);
}

/**
 * {@link success Get success info} API request payload options.
 */
export interface GetSuccessInfosOptions {
  sessionId: string;
}

export interface OrderSuccessInfo {
  session: unknown;
  customer: { email: string };
  invoice: unknown;
}

/**
 * ...
 *
 * @param options Request options bag.
 * @returns `OrderSearch.Results` data object.
 *
 * @see {@link ZephyrApi.Orders.orderSuccess `OrderSuccess`} API route in `zephyr-serverless`
 */
export async function success(options: GetSuccessInfosOptions) {
  return await server.get<OrderSuccessInfo>(
    `v2/orders/success?session_id=${options.sessionId}`,
  );
}

//#region Helper Functions

interface EffectiveOrderStructure {
  id: unknown;
  price: unknown;
  productId: unknown;
  quantity: unknown;
  stripeId?: unknown;
  total?: unknown;
  active?: unknown;
  allowBackorder?: unknown;
  behaviors?: unknown;
  bundle?: unknown;
  certification?: unknown;
  checkoutPinned?: unknown;
  createdAt?: unknown;
  customsPrice?: unknown;
  dataModel?: unknown;
  dependencies?: unknown;
  description?: unknown;
  downSell?: unknown;
  drones?: unknown;
  hideShop?: unknown;
  images?: unknown;
  instructionalVideo?: unknown;
  isDownsell?: unknown;
  isUpsell?: unknown;
  larn?: unknown;
  licenseExpiration?: unknown;
  limit?: unknown;
  name?: unknown;
  onSale?: unknown;
  phoneRequired?: unknown;
  pk?: unknown;
  salePrice?: unknown;
  scenes?: unknown;
  shippingOptions?: unknown;
  shippingService?: unknown;
  shippingTable?: unknown;
  sk?: unknown;
  sku?: unknown;
  stock?: unknown;
  tags?: unknown;
  taxCode?: unknown;
  type?: unknown;
  upSell?: unknown;
  updatedAt?: unknown;
  useGeneric?: unknown;
  videos?: unknown;
  weight?: unknown;
  accountBound?: unknown;
  baseLicense?: unknown;
  shipping?: unknown;
}

/**
 * Process data received from a request expected to be a {@link models.Order}.
 *
 * @param data Data received from the request.
 * @returns The processed data value.
 */
function processOrderData(data: unknown) {
  if (!isObject(data)) {
    throw new TypeError(
      '[createCartItem] value provided for CartItem data was not valid.',
    );
  }

  const id = dataUtils.assertIsString(data.id);

  const status = dataUtils.assertIsString(data.status);

  let userId, userName;

  if (isObject(data.user)) {
    userId = dataUtils.assertIsString(data.user.id);
    userName = dataUtils.assertIsString(data.user.name);
  } else {
    userId = '';
    userName = '';
  }

  const userInfo: models.Order.UserInfo = {
    id: userId,
    name: userName,
  };

  let organizationId, organizationName;

  if (isObject(data.organization)) {
    organizationId = dataUtils.assertIsString(data.organization.id);
    organizationName = dataUtils.assertIsString(data.organization.name);
  } else {
    organizationId = '';
    organizationName = '';
  }

  const organizationInfo: models.Order.UserInfo = {
    id: organizationId,
    name: organizationName,
  };

  const billingAddress = isObject(data.billingAddress)
    ? (data.billingAddress as unknown as models.Order.BillingAddress)
    : null;
  const shippingAddress = isObject(data.shippingAddress)
    ? (data.shippingAddress as unknown as models.Order.ShippingAddress)
    : null;

  data.transactionId = data.transactionId ?? '';
  data.currency = data.currency ?? 'usd';
  data.cart = data.cart ?? [];
  data.targetUsers = data.targetUsers ?? null;

  const order = dataUtils.buildDataObject<models.Order>(data, {
    pk: { type: 'string', required: true },
    sk: { type: 'string', required: true },
    // dataModel: { type: 'string', required: true },
    // grn: { type: 'string', required: true },
    id: { type: 'string', required: true },
    createdAt: { type: 'string', required: true },
    updatedAt: { type: 'string', required: true },
    transactionId: { type: 'string', required: true },
    email: { type: 'string', required: true },
    claimed: { type: 'string', required: false },
    cart: { type: 'array', required: true },
    phone: { type: 'string', required: false },
    referralCode: { type: 'string', required: false },
    discountCode: { type: 'string', required: false },
    billingFirst: { type: 'string', required: false },
    billingLast: { type: 'string', required: false },
    billingAddress1: { type: 'string', required: false },
    billingAddress2: { type: 'string', required: false },
    billingAddress3: { type: 'string', required: false },
    billingCity: { type: 'string', required: false },
    billingStateProvince: { type: 'string', required: false },
    billingPostalCode: { type: 'string', required: false },
    billingCountry: { type: 'string', required: false },
    shippingFirst: { type: 'string', required: false },
    shippingLast: { type: 'string', required: false },
    shippingAddress1: { type: 'string', required: false },
    shippingAddress2: { type: 'string', required: false },
    shippingAddress3: { type: 'string', required: false },
    shippingCity: { type: 'string', required: false },
    shippingStateProvince: { type: 'string', required: false },
    shippingPostalCode: { type: 'string', required: false },
    shippingCountry: { type: 'string', required: false },
    isShipping: { type: 'boolean', required: true },
    expedited: { type: 'boolean', required: true },
    currency: { type: 'string', required: true },
    productTotal: { type: 'number', required: true },
    shippingTotal: { type: 'number', required: true },
    taxTotal: { type: 'number', required: true },
    customsTotal: { type: 'number', required: true },
    discountTotal: { type: 'number', required: true },
    total: { type: 'number', required: true },
    status: { type: 'string', required: true },
    user: { type: 'object', required: false },
    organization: { type: 'object', required: false },
    billingAddress: { type: 'object', required: false },
    shippingAddress: { type: 'object', required: true },
    invoiceId: { type: 'string', required: false },
    invoiceNumber: { type: 'string', required: false },
    invoiceUrl: { type: 'string', required: false },
    invoicePdf: { type: 'string', required: false },
    targetUsers: { type: 'array', required: false },
  });

  order.cart = order.cart.map(sanitizeCartItem);

  // const _order: models.Order = {
  //   sk: dataUtils.assertIsString(data.sk),
  //   pk: dataUtils.assertIsString(data.pk),
  //   dataModel: dataUtils.assertIsString(data.dataModel),
  //   grn: dataUtils.assertIsString(data.grn),
  //   id,
  //   createdAt: dataUtils.assertIsString(data.createdAt),
  //   updatedAt: dataUtils.assertIsString(data.updatedAt),
  //   transactionId: dataUtils.assertIsString(data.transactionId),
  //   email: dataUtils.assertIsString(data.email),
  //   claimed: dataUtils.assertIsString(data.claimed, false),
  //   cart: dataUtils.assertIsArray(data.cart).map(sanitizeCartItem),
  //   phone: dataUtils.assertIsString(data.phone, false),
  //   referralCode: dataUtils.assertIsString(data.referralCode, false),
  //   discountCode: dataUtils.assertIsString(data.discountCode, false),
  //   billingFirst: dataUtils.assertIsString(data.billingFirst, false),
  //   billingLast: dataUtils.assertIsString(data.billingLast, false),
  //   billingAddress1: dataUtils.assertIsString(data.billingAddress1, false),
  //   billingAddress2: dataUtils.assertIsString(data.billingAddress2, false),
  //   billingAddress3: dataUtils.assertIsString(data.billingAddress3, false),
  //   billingCity: dataUtils.assertIsString(data.billingCity, false),
  //   billingStateProvince: dataUtils.assertIsString(
  //     data.billingStateProvince,
  //     false,
  //   ),
  //   billingPostalCode: dataUtils.assertIsString(data.billingPostalCode, false),
  //   billingCountry: dataUtils.assertIsString(data.billingCountry, false),
  //   shippingFirst: dataUtils.assertIsString(data.shippingFirst, false),
  //   shippingLast: dataUtils.assertIsString(data.shippingLast, false),
  //   shippingAddress1: dataUtils.assertIsString(data.shippingAddress1, false),
  //   shippingAddress2: dataUtils.assertIsString(data.shippingAddress2, false),
  //   shippingAddress3: dataUtils.assertIsString(data.shippingAddress3, false),
  //   shippingCity: dataUtils.assertIsString(data.shippingCity, false),
  //   shippingStateProvince: dataUtils.assertIsString(
  //     data.shippingStateProvince,
  //     false,
  //   ),
  //   shippingPostalCode: dataUtils.assertIsString(
  //     data.shippingPostalCode,
  //     false,
  //   ),
  //   shippingCountry: dataUtils.assertIsString(data.shippingCountry, false),
  //   isShipping: dataUtils.assertIsBoolean(data.isShipping),
  //   expedited: dataUtils.assertIsBoolean(data.expedited),
  //   productTotal: dataUtils.assertIsNumber(data.productTotal),
  //   shippingTotal: dataUtils.assertIsNumber(data.shippingTotal),
  //   taxTotal: dataUtils.assertIsNumber(data.taxTotal),
  //   customsTotal: dataUtils.assertIsNumber(data.taxTotal),
  //   discountTotal: dataUtils.assertIsNumber(data.discountTotal),
  //   total: dataUtils.assertIsNumber(data.total),
  //   status: status as models.Order.Status,

  //   // TODO: Check with Eric about whether this should ever be undefined or not.
  //   user: userInfo,
  //   organization: organizationInfo,
  //   billingAddress,
  //   shippingAddress,
  // };

  return order;
}

/**
 * Determine if a value is a valid {@link OrderSearch.Results}.
 *
 * @param value The value to check.
 * @returns `true` if the value is a valid {@link OrderSearch.Results},
 * otherwise `false`.
 */
function isValidSearchResults(value: unknown): value is OrderSearch.Results {
  return (
    isObject(value) &&
    isArray(value['items']) &&
    (isObject(value['lastEvaluatedKey']) || isNull(value['lastEvaluatedKey']))
  );
}

/**
 * Process data received from a request expected to be a
 * {@link OrderSearch.Results}.
 *
 * @param data Data received from the request.
 * @returns The processed data value.
 */
function processSearchResults(data: unknown) {
  if (!isValidSearchResults(data)) {
    throw new Error('Invalid search result data.');
  }

  data.items = data.items.map((item) => processOrderData(item));

  return data;
}

type OldCartItem = Omit<
  models.Order.CartItem,
  'price' | 'salePrice' | 'upSell' | 'downSell'
> & {
  price: string;
  salePrice: string;
  upSell: string;
  downSell: string;
};

/**
 * Sanitize value that (should) represent a
 * {@link models.Order.CartItem current} or {@link OldCartItem old} order cart
 * item data object.
 *
 * @param data Cart item data object
 * @returns A guaranteed sanitized {@link models.Order.CartItem cart item}
 */
function sanitizeCartItem(data: unknown) {
  if (!isObject(data)) {
    throw new TypeError(
      '[createCartItem] value provided for CartItem data was not valid.',
    );
  }

  const item = data as models.Order.CartItem | OldCartItem;

  let images: string[];

  if (isArray(item.images)) {
    images = item.images;
  } else if (isString(item.images)) {
    images = parse(item.images) ?? [];
  } else {
    images = [];
  }

  // Parse quantity-based string values into number.

  const price = dataUtils.parseFloat(item.price, true);
  const salePrice = dataUtils.parseFloat(item.salePrice);
  const upSell = dataUtils.parseFloat(item.upSell);
  const downSell = dataUtils.parseFloat(item.downSell);

  return {
    ...item,
    name: item.name || '[Missing Product Name]',
    images,
    price,
    salePrice,
    upSell,
    downSell,
  } as models.Order.CartItem;
}

//#endregion Helper Functions
