var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: _setup.classList },
    [
      _vm.processing
        ? _c(
            _setup.Overlay,
            { attrs: { background: "transparent" } },
            [
              !_vm.success && !_vm.errored
                ? _c(_setup.Spinner, { attrs: { size: 3, unit: "rem" } })
                : _vm._e(),
              _vm._v(" "),
              _vm.success ? _c(_setup.AnimatedSuccess) : _vm._e(),
              _vm._v(" "),
              _vm.errored ? _c(_setup.AnimatedError) : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "nav-pane" }, [
        _c(
          "div",
          { staticClass: "nav-pane-side-panel" },
          [
            _setup.isMobile
              ? _c("div", { staticClass: "nav-pane-header" }, [
                  _c(
                    "button",
                    {
                      staticClass: "pane-header-btn dismiss-btn",
                      on: { click: _setup.dismiss },
                    },
                    [_c(_setup.Icon, { attrs: { icon: _setup.faXmark } })],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.panes, function (pane) {
              return _c(
                "div",
                {
                  key: pane.id,
                  class: [
                    "side-panel-item",
                    { active: pane.id === _setup.activePaneId },
                  ],
                  on: {
                    click: function ($event) {
                      _setup.activePaneId = pane.id
                    },
                  },
                },
                [
                  _c(_setup.Icon, {
                    staticClass: "mr-3",
                    attrs: { icon: pane.icon },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(pane.label))]),
                ],
                1
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "nav-pane-main-panel" }, [
          _c("div", { staticClass: "nav-pane-header" }, [
            _setup.isMobile
              ? _c(
                  "button",
                  {
                    staticClass: "pane-header-btn",
                    on: { click: _setup.back },
                  },
                  [
                    _c(_setup.Icon, {
                      staticClass: "mr-2",
                      attrs: { icon: _setup.faChevronLeft },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Back")]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "pane-header-btn dismiss-btn",
                on: { click: _setup.dismiss },
              },
              [_c(_setup.Icon, { attrs: { icon: _setup.faXmark } })],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "main-panel-content" },
            [
              _setup.activePanel
                ? _vm._t("default", null, null, {
                    activePanel: _setup.activePanel,
                  })
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }