import type { Role, AuthenticationMethod } from '@models';

import { openModal } from '../open-modal';

export interface EnrollUserInMFAModalOptions {
  userEmail: string;
}

export type EnrollUserInMFAModalResult =
  import('./EnrollUserInMFA.vue').ModalResult;

/**
 * Enroll User in MFA Modal.
 *
 * @returns Modal promise.
 */
export async function enrollUserInMFA(options: EnrollUserInMFAModalOptions) {
  let result;

  try {
    result = await openModal<EnrollUserInMFAModalResult>({
      title: 'Secure Your Account',
      props: { ...options },
      component: () => import('./EnrollUserInMFA.vue'),
    });
  } catch {
    result = null;
  }

  return { enrolled: !!result?.enrolled } as EnrollUserInMFAModalResult;
}

export interface RemoveMFAOptions {
  authenticationMethod: AuthenticationMethod;
}

/**
 * Remove MFA Modal.
 *
 * @returns Modal promise.
 */
export async function removeMFA(options: RemoveMFAOptions) {
  let result;

  try {
    result = await openModal<{ mfaRemoved: boolean }>({
      title: 'REMOVE MFA',
      props: { ...options },
      component: () => import('./RemoveMFA.vue'),
    });
  } catch {
    result = null;
  }

  return { mfaRemoved: !!result?.mfaRemoved };
}

/**
 * Open Select Role modal.
 *
 * @returns Modal promise.
 */
export async function selectRole() {
  const modalOptions = {
    title: 'SELECT ROLE',
    component: () => import('./SelectRole.vue'),
  };

  try {
    return await openModal<Role['id']>(modalOptions);
  } catch {
    return null;
  }
}

/**
 * Open Generate Licenses modal.
 *
 * @returns Modal promise.
 */
export async function generateLicenses() {
  await openModal.safe({
    title: 'GENERATE LICENSES',
    component: () => import('./GenerateLicenses.vue'),
  });
}

/**
 * Open Idle Logout Warning modal.
 *
 * @return Modal promise.
 */
export async function idleLogoutWarning() {
  const modalOptions = {
    title: 'IDLE LOGOUT WARNING',
    component: () => import('./IdleLogoutWarning.vue'),
  };

  try {
    return await openModal<boolean>(modalOptions);
  } catch {
    return true;
  }
}

/**
 * Open Redeem Key modal.
 *
 * @returns Modal promise.
 */
export async function redeemKey() {
  await openModal.safe({
    title: 'REDEEM KEY',
    component: () => import('./RedeemKey.vue'),
  });
}

/**
 * Open Settings modal.
 *
 * @returns Modal promise.
 */
export async function settings() {
  await openModal.safe({
    title: 'SETTINGS',
    component: () => import('./SettingsV2.vue'),
    size: 'xl',
    // component: () => import('./Settings/index'),
  });

  // await openModal.safe({
  //   title: 'SETTINGS',
  //   size: 'xl',
  //   component: () => import('./SettingsV2.vue'),
  // });
}
