var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: _setup.tableClassList },
    [
      _c(_setup.Toolbar, {
        on: {
          maximize: function ($event) {
            _setup.fullscreen = true
          },
          minimize: function ($event) {
            _setup.fullscreen = false
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c(
            "Transition",
            { attrs: { appear: "" } },
            [
              _setup.tableOverlay
                ? _c(
                    _setup.TableOverlay,
                    _vm._b({}, "TableOverlay", _setup.tableOverlay, false)
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "table-wrapper" },
            [
              _c(
                "b-table",
                _vm._b(
                  {
                    staticClass: "mb-0",
                    attrs: {
                      "sort-compare": _setup.sortCompare,
                      responsive: "",
                      striped: "",
                    },
                    on: {
                      filtered: _setup.onTableFiltered,
                      "row-contextmenu": _setup.openFlyout,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "head(selector)",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "table-cell-checkbox" },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(_setup.InputCheckbox, {
                                        attrs: { size: "sm" },
                                        model: {
                                          value: _setup.allRowsSelected,
                                          callback: function ($$v) {
                                            _setup.allRowsSelected = $$v
                                          },
                                          expression: "allRowsSelected",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "head(operations)",
                          fn: function (header) {
                            return [
                              _c("div", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(header.label)),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "cell(selector)",
                          fn: function ({ index }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "table-cell-checkbox" },
                                [
                                  _c(
                                    "div",
                                    [
                                      _setup.page[index]
                                        ? _c(_setup.InputCheckbox, {
                                            attrs: { size: "sm" },
                                            model: {
                                              value:
                                                _setup.page[index].selected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.page[index],
                                                  "selected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "page[index].selected",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        _vm._l(_setup.customCells, function (cell) {
                          return {
                            key: `cell(${cell.key})`,
                            fn: function (data) {
                              return [
                                _c(
                                  cell.component,
                                  _vm._b(
                                    { key: cell.key, tag: "component" },
                                    "component",
                                    data,
                                    false
                                  )
                                ),
                              ]
                            },
                          }
                        }),
                        {
                          key: "cell(operations)",
                          fn: function (cell) {
                            return [
                              _setup.operations && cell.item
                                ? _c(
                                    _setup.CellOperations,
                                    _vm._b(
                                      {},
                                      "CellOperations",
                                      {
                                        item: cell.item,
                                        operations: _setup.operations,
                                      },
                                      false
                                    )
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _setup.displayedItems,
                      callback: function ($$v) {
                        _setup.displayedItems = $$v
                      },
                      expression: "displayedItems",
                    },
                  },
                  "b-table",
                  _setup.bTableProps,
                  false
                )
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-footer p-4" },
        [
          _c(
            "b-row",
            { staticClass: "justify-content-between align-items-center" },
            [
              _c(
                "b-col",
                { staticClass: "col-auto" },
                [
                  _c(
                    "b-input-group",
                    {
                      staticClass: "mr-2",
                      attrs: { prepend: "Show", append: "entries" },
                    },
                    [
                      _c("b-form-select", {
                        attrs: { options: _setup.pageSizeOptions },
                        model: {
                          value: _setup.itemsPerPage,
                          callback: function ($$v) {
                            _setup.itemsPerPage = $$v
                          },
                          expression: "itemsPerPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "col-auto" },
                [
                  _c("b-pagination", {
                    staticClass: "mb-0",
                    attrs: {
                      "total-rows": _setup.filteredRowCount,
                      "per-page": _setup.itemsPerPage,
                      "aria-controls": "my-table",
                    },
                    model: {
                      value: _setup.pageNumber,
                      callback: function ($$v) {
                        _setup.pageNumber = $$v
                      },
                      expression: "pageNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }