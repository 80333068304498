/**
 * Helper function to stringify the values of an Object.
 * Copied from Bootstrap's internal sortCompare on b-tables
 *
 * https://bootstrap-vue.org/docs/components/table#custom-sort-compare-routine
 *
 * @param unknown ...
 */
export function toString(value: unknown) {
  if (value === null || typeof value === 'undefined') {
    return '';
  } else if (value instanceof Object) {
    return Object.keys(value)
      .sort()
      .map((key) => toString(value[key]))
      .join(' ');
  } else {
    return String(value);
  }
}
