var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { staticClass: "add-ons-rack mt-4 mb-4" },
    [
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "mb-4 text-center" }, [
            _c("h2", [_vm._v(_vm._s(_vm.heading))]),
            _vm._v(" "),
            _vm.description
              ? _c("p", [_vm._v(_vm._s(_vm.description))])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        _vm._l(_vm.products, function (product) {
          return _c(
            "b-col",
            { key: product.id, staticClass: "card-col", attrs: { lg: "4" } },
            [
              _c(
                _setup.AddOnsRackCard,
                _vm._b({}, "AddOnsRackCard", { product }, false)
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }