import { Module } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { Order } from '@models';
import { modals } from '@modals';
import * as currencies from '@utils/currencies';
import { RoleId } from '@values/roles';

import { faEye } from '@icons/solid/faEye';

const columns: TablePanel.Column<Order>[] = [
  {
    key: 'id',
    label: 'ID',
    value: 'id',
  },
  {
    key: 'createdAt',
    label: 'Created',
    type: 'dateTime',
    value: 'createdAt',
  },
  {
    key: 'email',
    label: 'Email',
    value: 'email',
  },
  {
    key: 'user',
    label: 'User',
    value: ({ user }) => (user?.name ? user.name : 'N/A'),
  },
  {
    key: 'total',
    label: 'Total',
    value: ({ total, currency }) => {
      return currencies.format({
        amount: total,
        currency,
        unitType: 'smallest',
      });
    },
  },
  {
    key: 'referralCode',
    label: 'Referral Code',
    value: 'referralCode',
    placeholder: 'N/A',
  },
];

const operations: TablePanel.Row.Operation<Order>[] = [
  {
    label: 'View',
    icon: faEye,
    fn: (order) => {
      void modals.order.view({ order });
    },
  },
];

const filterFields = ['id', 'email', 'createdAt', 'referralCode'];

const filterTags: TablePanel.FilterTag[] = [
  {
    key: 'paidOrders',
    label: 'Paid Orders',
  },
  {
    key: 'referralOrders',
    label: 'Referral Orders',
  },
];

@Module({ namespaced: true })
export class AllTransactionsTablePanel
  extends TablePanel<Order>
  implements TablePanel.Props<Order>
{
  readonly module = 'orders';
  readonly loadAction = 'list';
  readonly loadPageAction = 'loadPage';
  readonly pk = 'id';
  readonly label = 'ALL ORDERS';
  readonly columns = columns;
  readonly operations = operations;
  readonly filterFields = filterFields;
  readonly tableSortType = 'createdAt';
  readonly tablePageSize = 50;
  readonly primaryKey = 'id';

  readonly filterTags = filterTags;

  get enabled() {
    return this.isActiveRole(9);
  }

  get progressive() {
    return this.isActiveRole(RoleId.LasAdmin);
  }
}
